import React, { useState, useEffect, useRef } from 'react';
import { ImageList, ImageListItem, Box } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styles from './Photos.module.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import LazyLoad from 'react-lazyload'; // LazyLoad ekleniyor

const Photos = () => {
  const [media, setMedia] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(null);
  const videoRefs = useRef([]);

  const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.wmv', '.flv', '.mkv'];

  const isVideo = (path) => videoExtensions.some((ext) => path.toLowerCase().endsWith(ext));

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get('https://api.dugun.info/photos');
        if (response.data && response.data.media) {
          const mediaData = response.data.media.map((item, index) => ({
            original: item.path,
            thumbnail: item.path,
            description: item.title,
            index: index,
            isVideo: isVideo(item.path)
          }));
          setMedia(mediaData);
        } else {
          setError('Error: Response data format is incorrect.');
        }
      } catch (error) {
        setError('Error fetching media: ' + error.message);
      }
    };

    fetchMedia();
  }, []);

  const openGallery = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeGallery = () => {
    setIsOpen(false);
    videoRefs.current.forEach((videoRef) => {
      if (videoRef) {
        videoRef.pause();
        videoRef.currentTime = 0;
      }
    });
  };

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains(styles.lightboxContainer)) {
      closeGallery();
    }
  };

  const handleVideoClick = (index) => {
    const video = videoRefs.current[index];
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const renderCustomItem = (item) => {
    if (item.isVideo) {
      return (
        <div className={styles.videoContainer}>
          <LazyLoad height={200}>
            <video
              ref={(el) => videoRefs.current[item.index] = el}
              style={{ width: '100%', height: 'auto', maxHeight: '80vh' }}
              autoPlay={false}
              muted
              controls
            >
              <source src={item.original} />
              Your browser does not support the video tag.
            </video>
          </LazyLoad>
        </div>
      );
    } else {
      return (
        <LazyLoad height={200}>
          <img src={item.original} alt={item.description} style={{ width: '100%', height: 'auto', maxHeight: '80vh' }} />
        </LazyLoad>
      );
    }
  };

  const renderImageGallery = () => (
    <div className={styles.lightboxContainer} onClick={handleBackgroundClick}>
      <div className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
        <IconButton className={styles.closeButton} onClick={closeGallery}>
          <CloseIcon color='primary' />
        </IconButton>
        <ImageGallery
          items={media}
          startIndex={currentIndex}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          onSlide={(currentIndex) => {
            setCurrentIndex(currentIndex);
            videoRefs.current.forEach((videoRef) => {
              if (videoRef) {
                videoRef.pause();
              }
            });
          }}
          renderItem={renderCustomItem}
        />
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {error ? (
        <div className={styles.error}>{error}</div>
      ) : (
        <ImageList variant="masonry" cols={3} gap={0.5}>
          {media.map((item, index) => (
            <ImageListItem key={index} cols={1} sx={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}>
              <LazyLoad height={200} once>
                {item.isVideo ? (
                  <div className={styles.videoWrapper}>
                    <Box
                      component="video"
                      src={item.thumbnail}
                      alt={item.description}
                      sx={{ width: '100%', cursor: 'pointer' }}
                      onClick={() => openGallery(index)}
                      className={styles.mediaItem}
                      autoPlay={false}
                      muted
                      showThumbnails={true}
                    />
                    <PlayCircleOutlineIcon className={styles.videoIcon} />
                  </div>
                ) : (
                  <Box
                    component="img"
                    src={item.thumbnail}
                    alt={item.description}
                    sx={{ width: '100%', cursor: 'pointer' }}
                    onClick={() => openGallery(index)}
                    className={styles.mediaItem}
                  />
                )}
              </LazyLoad>
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {isOpen && renderImageGallery()}
    </div>
  );
};

export default Photos;
