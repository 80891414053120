// AlertModal.jsx

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import styles from './AlertModal.module.css';

const AlertModal = ({ isOpen, onClose, message, isError }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.modalContainer}>
        <h2 className={styles.modalTitle}>{isError ? 'Uyarı!' : 'Bilgi'}</h2>
        <p className={styles.modalContent}>{message}</p>
        <div className={styles.modalActions}>
          <Button variant="contained" className={styles.modalButton} onClick={onClose}>
            Tamam
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default AlertModal;
