import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import FavoriteIcon from '@mui/icons-material/Favorite';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerText}>
        <span><CodeIcon style={{ fontSize: 15 }} /></span>
        <span>seozdemir 2024 </span>
        <span><CodeIcon style={{ fontSize: 15 }} /></span>

      </div>
      <div className={styles.footerText}>
        <span>Burçak</span>
        <span><FavoriteIcon style={{ fontSize: 15 }} /></span>
        <span>Eren</span>
      </div>
    </footer>
  );
};

export default Footer;
