// theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8a3ab9', // Örnek bir renk, Instagram tarzı renkleri buraya uygun olarak değiştirin
    },
    secondary: {
      main: '#0095f6',
    },
    error: {
      main: '#ed4956',
    },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default theme;
