// App.jsx

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Menu from './components/Menu/Menu';
import Photos from './components/Photos/Photos.jsx';
import theme from './theme';
import Loading from './components/Loading/Loading'; // Loading bileşenini ekliyoruz

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Loading setIsLoading={setIsLoading} />
      ) : (
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/photos" element={<Photos />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
