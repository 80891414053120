// Loading.jsx

import React, { useEffect } from 'react';
import styles from './Loading.module.css';
import { Hearts } from 'react-loader-spinner';

const Loading = ({ setIsLoading }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // 1 saniyelik loading süresi

    return () => clearTimeout(timer);
  }, [setIsLoading]);

  return (
    <div className={styles.loadingScreen}>
      <div className={styles.loadingContent}>
        <Hearts
          height="150"
          width="150"
          color="#ffffff"
          ariaLabel="hearts-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <h4>HOŞGELDİNİZ</h4>
      </div>
    </div>
  );
};

export default Loading;
