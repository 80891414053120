import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './CircularProgressWithLabel.module.css';

function CircularProgressWithLabel({ value }) {
  return (
    <Box className={styles.circularProgressContainer}>
      <CircularProgress variant="determinate" value={value} size={24} />
      <Box className={styles.circularProgressLabel}>
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
