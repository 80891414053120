import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        <ul>
          <li>
            <Link to="/" className={styles.link}>
              <HomeIcon className={styles.icon} style={{ fontSize: 13 }} />
              Anasayfa
            </Link>
          </li>
          <li>
            <Link to="/Menu" className={styles.link}>
              <RestaurantIcon className={styles.icon} style={{ fontSize: 13 }} />
              Menü
            </Link>
          </li>
          <li>
            <Link to="/Photos" className={styles.link}>
              <PhotoCameraIcon className={styles.icon} style={{ fontSize: 13 }} />
              Galeri
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
