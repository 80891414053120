// Menu.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Menu.module.css';

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get('https://api.dugun.info/menu');
        if (response.data && response.data.menuItems) {
          setMenuItems(response.data.menuItems);
        } else {
          setError('Error: No menu items found.');
        }
      } catch (error) {
        setError('Error fetching menu items: ' + error.message);
      }
    };

    fetchMenuItems();
  }, []);

  return (
    <div className={styles.menuContainer}>
      {error ? (
        <div className={styles.error}>{error}</div>
      ) : (
        <ul>
          {menuItems.map((item) => (
            <li key={item.id} className={styles.menuItem}>
              <strong>{item.name}</strong> - <span className={styles.menuItemDescription}>{item.description}</span>
            </li>
          ))}
          <li> <div className={styles.plateWrapper}>
            <div className={styles.plate}>
              <div className={styles.inner_plate}>
              </div>
            </div>
          </div></li>
        </ul>
        
      )}
    </div>
  );
};

export default Menu;
